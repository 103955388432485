import React from 'react';
import { View, Text, StyleSheet, Modal, FlatList, TouchableOpacity } from 'react-native';
import TransactionList from './TransactionList';
import { EVENT_ACTIONS, USER_TYPE } from '../constants/Constant';
import { Colors } from '../constants/theme';
import useDataStore from '../stores/dataStore';
import MixpanelTracker from '../utils/MixpanelTracker';
import html2pdf from 'html2pdf.js';

const TransactionModal = ({ data, visible, closeModal, title, startDate, endDate, openingBalance, closingBalance }) => {
    const [userData] = useDataStore((state) => [state.userData]);
    let today = new Date();

    const getCurrentDate = (parseDate) => {

        var date = parseDate.getDate();

        var month = parseDate.getMonth() + 1; // Adding 1 because months start from 0

        var year = parseDate.getFullYear();

        month = (month < 10) ? '0' + month : month;
        date = (date < 10) ? '0' + date : date;

        var dateMonthYear = date + '-' + month + '-' + year;
        return dateMonthYear;
    }

    const generateHTMLContent = () => {
        let currentDate = getCurrentDate(today);
        let total = 0;
        let totalCredit = 0;
        let totalDebit = 0;
        let html = `
        <html>
            <head>
                <title>Transaction Report</title>
                <style>
                    body {
                        margin: 30px;
                    }
                    #details {
                        background-color: #fff;
                    }
                    #details_tr {
                        background-color: #fff;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #dddddd;
                    }

                    h1 {
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                    }
                    #border {
                        border: 1px solid #000;
                    }
                    table {
                      font-family: arial, sans-serif;
                      border-collapse: collapse;
                      width: 100%;
                    }
                    
                    td, th {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                      max-width: 50px;
                    }
                    
                    tr:nth-child(even) {
                      background-color: #f2f2f2;
                    }
                    
                    #endctn p{
                        display: inline-block;
                        margin-right: 10px;
                    }
                </style>
            </head>
            <body>
                <h1>Transaction Report</h1>
                <h3>${getCurrentDate(startDate)} to ${getCurrentDate(endDate)}</h3>
                <div id="border"></div> <br>
                <table id="details">
                    <tr id ="details_tr">
                        <th colspan="4">Details</th>
                        <th colspan="4">Date</th>
                        <th colspan="4">Opening Bal</th>
                        <th colspan="4">Closing Bal</th>
                    </tr>
                    <tr id ="details_tr">
                        <td colspan="4">${title}</td>
                        <td colspan="4" rowspan="2">${currentDate}</td>
                        <td colspan="4" rowspan="2">${openingBalance}</td>
                        <td colspan="4" rowspan="2">${closingBalance}</td>
                    </tr>
                    
                </table>
                <br>
                <div style="border: 1px solid #000;"></div> <br>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Type</th>
                            <th>User</th>
                            <th>Credit</th>
                            <th>Debit</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
    `;

        // Add filtered transactions to the table
        data?.forEach((transaction) => {
            total += parseFloat(transaction?.amount);
            if (transaction?.listType === USER_TYPE.CLIENTS || transaction?.listType === 'Other income') {
                totalCredit += parseFloat(transaction?.amount);
            } else {
                totalDebit += parseFloat(transaction?.amount);
            }
            html += `
            <tr>
                <td>${transaction?.date}</td>
                <td>${transaction?.listType?.charAt(0).toUpperCase() + transaction?.listType.slice(1).toLowerCase()}</td>
                <td>${transaction?.tName}</td>
                <td>${transaction?.listType === USER_TYPE.CLIENTS || transaction?.listType === 'Other income' ? transaction?.amount : '-'}</td>
                <td>${transaction?.listType === USER_TYPE.CLIENTS || transaction?.listType === 'Other income' ? '-' : transaction?.amount}</td>
                <td>${transaction?.actionBy}</td>
            </tr>
        `;
        });

        html += `
        <tr>
                <td><strong>Total Amounts:</strong></td>
                <td></td>
                <td></td>
                <td><strong>₹ ${totalCredit} Cr</strong></td>
                <td><strong>₹ ${totalDebit} Dr</strong></td>
                <td></td>
            </tr>
                    </tbody>
                </table>
                <br>
            </body>
        </html>
    `;
        return html;
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const formattedDate = new Date(date).toLocaleString('en-US', options).replace(',', '').replace(/ /g, '-');
        return formattedDate;
    };

    const handleDownloadPDF = async () => {
        try {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.DOWNLOAD_PDF_BUTTON_CLICKED, userData);
            const htmlContent = generateHTMLContent();
            let formatedDate = formatDate(today);
            const fileName = `${title}_${formatedDate}.pdf`;

            const element = document.createElement("div");
            element.innerHTML = htmlContent;

            html2pdf(element, {
                filename: fileName,
            });
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <Modal
            visible={visible}
            animationType="slide"
            transparent={true}
            onRequestClose={closeModal}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>{title}</Text>
                    {data?.length > 0 ? (
                        <FlatList
                            data={data}
                            style={{ maxHeight: '80vh', overflow: 'auto' }}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({ item }) => (
                                <TransactionList
                                    comment={item.tName}
                                    date={item.date}
                                    time={item.date + ' ' + item.time}
                                    amount={item.amount}
                                    user={item.actionBy}
                                    isDisable={true}
                                    // commentFactoryExpense={item.comment}
                                    type={item?.clientId || item?.incomeType ? USER_TYPE.CLIENTS : null}
                                    listType={item.listType}
                                />
                            )}
                        />
                    ) : (
                        <View style={styles.noTransactionContainer}>
                            <Text style={styles.noTransactionText}>No transaction found</Text>
                        </View>
                    )}
                    <View style={styles.BottomButtonContainer}>
                        <TouchableOpacity onPress={handleDownloadPDF}>
                            <Text style={[styles.closeButton, { color: Colors.primary }]}>Download Report</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={closeModal}>
                            <Text style={styles.closeButton}>Close</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 5,
        marginVertical: 15,
        borderRadius: 10,
        elevation: 5,
        minWidth: '90%',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 5,
        marginTop: 5,
        textAlign: 'center',
    },
    BottomButtonContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: 10,
        padding: 10
    },
    closeButton: {
        fontSize: 16,
        color: 'red',
    },
    noTransactionContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        height: '88%'
    },
    noTransactionText: {
        fontSize: 16,
        color: 'black',
    },
});

export default TransactionModal;
