import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';
import { USERS_API } from '../env-config/api-config';
import { APP_ROLE, EVENT_ACTIONS, USER_TYPE } from '../constants/Constant';
import Loader from '../components/Loader';
import { FontAwesome5 } from '@expo/vector-icons';
import { Colors } from '../constants/theme';
import TransactionModal from '../components/TransactionModal';
import ShowAlert from '../modules/AlertModule';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { convertToDateTime, parseDate } from '../utils/dateUtil';
import { makeApiRequest } from '../utils/apiUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";
import DropDownPicker from 'react-native-dropdown-picker';

const DayTransactionScreen = () => {
    const isFocused = useIsFocused();
    const [API_CONFIG, userData, employeeData, supplierData, setSupplierData, clientsData, setClientsData, setEmployeeData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.employeeData, state.supplierData, state.setSupplierData, state.clientsData, state.setClientsData, state.setEmployeeData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const [empAmount, setEmpAmount] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [suppAmount, setSuppAmount] = useState(null);
    const [suppList, setSuppList] = useState(null);
    const [clientAmount, setClientAmount] = useState(null);
    const [clientList, setClientList] = useState(null);
    const [accountAmount, setAccountAmount] = useState(null);
    const [accountList, setAccountList] = useState(null);
    const [thekedarAmount, setThekedarAmount] = useState(null);
    const [thekedarList, setThekedarList] = useState(null);
    const [incomeAmount, setIncomeAmount] = useState(null);
    const [otherIncomeList, setOtherIncomeList] = useState(null);
    const [totalBalance, setTotalBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isStartDatePickerVisible, setStartDatePickerVisibility] = useState(false);
    const [isEndDatePickerVisible, setEndDatePickerVisibility] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const today = new Date();
    const [isEmpModalVisible, setEmpModalVisible] = useState(false);
    const [isSuppModalVisible, setSuppModalVisible] = useState(false);
    const [isClientModalVisible, setClientModalVisible] = useState(false);
    const [isAccountModalVisible, setAccountModalVisible] = useState(false);
    const [isThekedarModalVisible, setThekedarModalVisible] = useState(false);
    const [isIncomeModalVisible, setIncomeModalVisible] = useState(false);
    const [isAllModalVisible, setAllModalVisible] = useState(false);
    const [alltransaction, setAlltransaction] = useState([]);
    const [transactData, setTransactData] = useState();
    const [accountData, setAccountData] = useState(null);
    const [incomeData, setIncomeData] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState('total');
    const [open, setOpen] = useState(false);
    const [openingBalance, setOpeningBalance] = useState(0);
    const [closingBalance, setClosingBalance] = useState(0);
    const [openingBalanceEmployee, setOpeningBalanceEmployee] = useState(0);
    const [closingBalanceEmployee, setClosingBalanceEmployee] = useState(0);
    const [openingBalanceSupplier, setOpeningBalanceSupplier] = useState(0);
    const [closingBalanceSupplier, setClosingBalanceSupplier] = useState(0);
    const [openingBalanceClient, setOpeningBalanceClient] = useState(0);
    const [closingBalanceClient, setClosingBalanceClient] = useState(0);
    const [openingBalanceAccounts, setOpeningBalanceAccounts] = useState(0);
    const [closingBalanceAccounts, setClosingBalanceAccounts] = useState(0);
    const [openingBalanceThekedar, setOpeningBalanceThekedar] = useState(0);
    const [closingBalanceThekedar, setClosingBalanceThekedar] = useState(0);
    const [openingBalanceOther, setOpeningBalanceOther] = useState(0);
    const [closingBalanceOther, setClosingBalanceOther] = useState(0);
    let filteredTransactions, transData;
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.DAY_TRANSACTION_CLICKED, userData);
            fetchData();
        }
    }, [isFocused]);

    const convertDate = (currentDate) => {
        const monthAbbreviations = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const formattedDate = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
        return `${formattedDate} ${monthAbbreviations[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
    }

    const sortDateLatest = (data) => {
        data.sort(function (a, b) {
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);
            return dateB - dateA;
        });
    };

    useEffect(() => {
        if (dataLoaded) filterData(convertDate(startDate), convertDate(endDate));
    }, [dataLoaded, value])

    const fetchData = async () => {
        try {
            const response = await makeApiRequest('get', API_CONFIG.TRANSACTIONS, authToken, refreshToken, setAuthToken, setRefreshToken);
            setTransactData(response);
            transData = response;

            const dataEmp = await makeApiRequest('get', API_CONFIG.EMPLOYEES, authToken, refreshToken, setAuthToken, setRefreshToken);
            setEmployeeData(dataEmp);

            const dataSupp = await makeApiRequest('get', API_CONFIG.SUPPLIERS, authToken, refreshToken, setAuthToken, setRefreshToken);
            setSupplierData(dataSupp);

            const dataClient = await makeApiRequest('get', API_CONFIG.CLIENTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            setClientsData(dataClient);

            const accountResponse = await makeApiRequest('get', API_CONFIG.ACCOUNTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            setAccountData(accountResponse);

            const incomeResponse = await makeApiRequest('get', API_CONFIG.OTHER_INCOME, authToken, refreshToken, setAuthToken, setRefreshToken);
            setIncomeData(incomeResponse);
            setDataLoaded(true);
        }
        catch (error) {
            setLoading(false);
            console.error('Error fetching daily transaction data:', error);
            handleAlertForError(error);
        }
    }

    const filterData = async (startDateFormatted, endDateFormatted) => {
        filteredTransactions = {};

        let amount = 0;
        let transactions = [];
        let alltrans = [];
        let transactionData;
        let clientCredited = 0;
        let employeeDebited = 0;
        let supplierDebited = 0;
        let accountsDebited = 0;
        let thekedarDebited = 0;
        let otherIncomeCredited = 0;

        let clientCreditedEnd = 0;
        let employeeDebitedEnd = 0;
        let supplierDebitedEnd = 0;
        let accountsDebitedEnd = 0;
        let thekedarDebitedEnd = 0;
        let otherIncomeCreditedEnd = 0;

        let todayObj = convertToDateTime(convertDate(today));
        if (!transactData) transactionData = transData;
        else transactionData = transactData;
        for (const clientId in transactionData.clients) {
            const clientIds = Object.keys(transactionData.clients[clientId]);
            clientIds.forEach(clientIdKey => {
                const clientTransactions = transactionData.clients[clientId][clientIdKey];
                for (const transactionId in clientTransactions) {
                    const transaction = clientTransactions[transactionId];
                    const transactionDate = convertToDateTime(transaction.date);
                    const startDateObj = convertToDateTime(startDateFormatted);
                    const endDateObj = convertToDateTime(endDateFormatted)

                    if (transactionDate >= startDateObj && transactionDate <= endDateObj && (value === 'total' || value === transaction.actionBy.toLowerCase())) {
                        // Include transaction within the selected date range
                        if (!filteredTransactions.clients) filteredTransactions.clients = {};
                        if (!filteredTransactions.clients[clientId]) filteredTransactions.clients[clientId] = {};
                        if (!filteredTransactions.clients[clientId][clientIdKey]) filteredTransactions.clients[clientId][clientIdKey] = {};
                        filteredTransactions.clients[clientId][clientIdKey][transactionId] = transaction;
                        amount += parseFloat(transaction.amount);
                        clientCredited += parseFloat(transaction.amount);
                        transaction['tName'] = clientsData[transaction.clientId]?.name;
                        transaction['listType'] = USER_TYPE.CLIENTS;
                        transactions.push(transaction);
                        alltrans.push(transaction);
                    }
                    if (transactionDate > endDateObj && transactionDate <= todayObj) {
                        employeeDebitedEnd += parseFloat(transaction.amount);
                    }
                }
            });
        }
        sortDateLatest(transactions);
        setClientAmount(amount);
        setClientList(transactions);
        amount = 0;
        transactions = [];
        for (const userId in transactionData.employees) {
            const userTransactions = transactionData.employees[userId];
            for (const transactionId in userTransactions) {
                const transaction = userTransactions[transactionId];

                const transactionDate = convertToDateTime(transaction.date);
                const startDateObj = convertToDateTime(startDateFormatted);
                const endDateObj = convertToDateTime(endDateFormatted)

                if (transactionDate >= startDateObj && transactionDate <= endDateObj && (value === 'total' || value === transaction.actionBy.toLowerCase())) {
                    if (!filteredTransactions.employees) filteredTransactions.employees = {};
                    if (!filteredTransactions.employees[userId]) filteredTransactions.employees[userId] = {};
                    filteredTransactions.employees[userId][transactionId] = transaction;
                    amount += parseFloat(transaction.amount);
                    employeeDebited += parseFloat(transaction.amount);
                    transaction['tName'] = employeeData[transaction.userId]?.name;
                    transaction['listType'] = USER_TYPE.EMPLOYEES;
                    transactions.push(transaction);
                    alltrans.push(transaction);
                }
                if (transactionDate > endDateObj && transactionDate <= todayObj) {
                    employeeDebitedEnd += parseFloat(transaction.amount);
                }
            }
        }
        sortDateLatest(transactions);
        setEmpAmount(amount);
        amount = 0;
        setEmpList(transactions);
        transactions = [];
        // Loop through suppliers
        for (const supplierId in transactionData.suppliers) {
            const supplierTransactions = transactionData.suppliers[supplierId];
            for (const transactionId in supplierTransactions) {
                const transaction = supplierTransactions[transactionId];

                const transactionDate = convertToDateTime(transaction.date);
                const startDateObj = convertToDateTime(startDateFormatted);
                const endDateObj = convertToDateTime(endDateFormatted)

                if (transactionDate >= startDateObj && transactionDate <= endDateObj && (value === 'total' || value === transaction.actionBy.toLowerCase())) {
                    if (!filteredTransactions.suppliers) filteredTransactions.suppliers = {};
                    if (!filteredTransactions.suppliers[supplierId]) filteredTransactions.suppliers[supplierId] = {};
                    filteredTransactions.suppliers[supplierId][transactionId] = transaction;
                    amount += parseFloat(transaction.amount);
                    supplierDebited += parseFloat(transaction.amount);
                    transaction['tName'] = supplierData[transaction.userId]?.name;
                    transaction['listType'] = USER_TYPE.SUPPLIERS;
                    transactions.push(transaction);
                    alltrans.push(transaction);
                }
                if (transactionDate > endDateObj && transactionDate <= todayObj) {
                    supplierDebitedEnd += parseFloat(transaction.amount);
                }
            }
        }
        sortDateLatest(transactions);
        setSuppAmount(amount);
        amount = 0;
        setSuppList(transactions);
        transactions = [];
        for (const accountId in transactionData.accounts) {
            const accountsTransactions = transactionData?.accounts[accountId];
            const transactionDate = convertToDateTime(accountsTransactions?.date);
            const startDateObj = convertToDateTime(startDateFormatted);
            const endDateObj = convertToDateTime(endDateFormatted)

            if (transactionDate >= startDateObj && transactionDate <= endDateObj && (value === 'total' || value === accountsTransactions.actionBy.toLowerCase())) {
                amount += parseFloat(accountsTransactions?.amount);
                accountsDebited += parseFloat(accountsTransactions.amount);
                accountsTransactions['tName'] = accountData[accountsTransactions?.accountType];
                accountsTransactions['listType'] = USER_TYPE.ACCOUNTS;
                transactions.push(accountsTransactions);
                alltrans.push(accountsTransactions);
            }
            if (transactionDate > endDateObj && transactionDate <= todayObj) {
                accountsDebitedEnd += parseFloat(accountsTransactions.amount);
            }
        }
        sortDateLatest(transactions);
        setAccountAmount(amount);
        amount = 0;
        setAccountList(transactions);
        transactions = [];

        for (const thekedarId in transactionData.thekedars) {
            const thekedarTransactions = transactionData.thekedars[thekedarId];
            for (const transactionId in thekedarTransactions) {
                const transaction = thekedarTransactions[transactionId];

                const transactionDate = convertToDateTime(transaction.date);
                const startDateObj = convertToDateTime(startDateFormatted);
                const endDateObj = convertToDateTime(endDateFormatted)

                if (transactionDate >= startDateObj && transactionDate <= endDateObj && (value === 'total' || value === transaction.actionBy.toLowerCase())) {
                    if (!filteredTransactions.thekedars) filteredTransactions.thekedars = {};
                    if (!filteredTransactions.thekedars[thekedarId]) filteredTransactions.thekedars[thekedarId] = {};
                    filteredTransactions.thekedars[thekedarId][transactionId] = transaction;
                    amount += parseFloat(transaction.amount);
                    thekedarDebited += parseFloat(transaction.amount);
                    transaction['tName'] = transaction.thekedarName;
                    transaction['listType'] = USER_TYPE.THEKEDARS;
                    transactions.push(transaction);
                    alltrans.push(transaction);
                }
                if (transactionDate > endDateObj && transactionDate <= todayObj) {
                    thekedarDebitedEnd += parseFloat(transaction.amount);
                }
            }
        }
        sortDateLatest(transactions);
        setThekedarAmount(amount);
        amount = 0;
        setThekedarList(transactions);
        transactions = [];
        for (const otherIncomeId in transactionData.income) {
            const otherIncome = transactionData.income[otherIncomeId];
            const transactionDate = convertToDateTime(otherIncome.date);
            const startDateObj = convertToDateTime(startDateFormatted);
            const endDateObj = convertToDateTime(endDateFormatted);

            if (transactionDate >= startDateObj && transactionDate <= endDateObj && (value === 'total' || value === otherIncome.actionBy.toLowerCase())) {
                amount += parseFloat(otherIncome.amount);
                otherIncomeCredited += parseFloat(otherIncome.amount);
                otherIncome['tName'] = incomeData[otherIncome.incomeType];
                otherIncome['listType'] = 'Other income';
                transactions.push(otherIncome);
                alltrans.push(otherIncome)
            }
            if (transactionDate > endDateObj && transactionDate <= todayObj) {
                otherIncomeCreditedEnd += parseFloat(otherIncome.amount);
            }
        }
        sortDateLatest(transactions);
        sortDateLatest(alltrans);
        setIncomeAmount(amount);
        amount = 0;
        setOtherIncomeList(transactions);
        transactions = [];
        setAlltransaction(alltrans)

        let usersData = await makeApiRequest('get', `${USERS_API}/${userData.factory}`, authToken, refreshToken, setAuthToken, setRefreshToken);
        let totalAmount = 0;
        let allUsers = [{ label: 'All', value: 'total' }];
        for (const key in usersData) {
            if (usersData[key].amount && (usersData[key].role === APP_ROLE.OWNER || usersData[key].role === APP_ROLE.SUPERVISOR)) {
                allUsers.push({ label: usersData[key].username, value: usersData[key].username })
                if (value === 'total') {
                    totalAmount += parseInt(usersData[key].amount);
                }
            }
            if (usersData[key].amount && (usersData[key].username === value)) {
                totalAmount += parseInt(usersData[key].amount);
            }
        }
        setOpeningBalance(totalAmount - clientCredited - clientCreditedEnd - otherIncomeCredited - otherIncomeCreditedEnd + accountsDebited + accountsDebitedEnd + employeeDebited + employeeDebitedEnd + supplierDebited + supplierDebitedEnd + thekedarDebited + thekedarDebitedEnd);
        setClosingBalance(totalAmount - clientCreditedEnd - otherIncomeCreditedEnd + accountsDebitedEnd + employeeDebitedEnd + supplierDebitedEnd + thekedarDebitedEnd);

        setOpeningBalanceEmployee(totalAmount + employeeDebited + employeeDebitedEnd);
        setClosingBalanceEmployee(totalAmount + employeeDebitedEnd);

        setOpeningBalanceAccounts(totalAmount + accountsDebited + accountsDebitedEnd);
        setClosingBalanceAccounts(totalAmount + accountsDebitedEnd);

        setOpeningBalanceClient(totalAmount - clientCredited - clientCreditedEnd);
        setClosingBalanceClient(totalAmount - clientCreditedEnd);

        setOpeningBalanceOther(totalAmount - otherIncomeCredited - otherIncomeCreditedEnd);
        setClosingBalanceOther(totalAmount - otherIncomeCreditedEnd);

        setOpeningBalanceSupplier(totalAmount + supplierDebited + supplierDebitedEnd);
        setClosingBalanceSupplier(totalAmount + supplierDebitedEnd);

        setOpeningBalanceThekedar(totalAmount + thekedarDebited + thekedarDebitedEnd);
        setClosingBalanceThekedar(totalAmount + thekedarDebitedEnd);

        setUsers(allUsers);
        setTotalBalance(totalAmount);
        setLoading(false);
    };

    const showStartDatePicker = () => {
        setStartDatePickerVisibility(true);
    };

    const showEndDatePicker = () => {
        setEndDatePickerVisibility(true);
    };

    const hideStartDatePicker = () => {
        setStartDatePickerVisibility(false);
    };

    const hideEndDatePicker = () => {
        setEndDatePickerVisibility(false);
    };

    const handleStartDateConfirm = (date) => {
        setStartDate(date);
        hideStartDatePicker();
        filterData(convertDate(date), convertDate(endDate));
    };

    const handleEndDateConfirm = (date) => {
        // Check if the selected end date is before the start date
        if (startDate && date < startDate) {
            setEndDatePickerVisibility(false);
            ShowAlert("Wrong Date", "End date cannot be before the start date.", "Ok");
        } else {
            setEndDate(date);
            hideEndDatePicker();
            filterData(convertDate(startDate), convertDate(date));
        }
    };

    const showEmpModal = () => {
        setEmpModalVisible(true);
    };

    const hideEmpModal = () => {
        setEmpModalVisible(false);
    };

    const showSuppModal = () => {
        setSuppModalVisible(true);
    };

    const hideSuppModal = () => {
        setSuppModalVisible(false);
    };

    const showClientModal = () => {
        setClientModalVisible(true);
    };

    const hideClientModal = () => {
        setClientModalVisible(false);
    };

    const showAccountModal = () => {
        setAccountModalVisible(true);
    };

    const hideAccountModal = () => {
        setAccountModalVisible(false);
    };

    const showThekedarModal = () => {
        setThekedarModalVisible(true);
    };

    const hideThekedarModal = () => {
        setThekedarModalVisible(false);
    };

    const showIncomeModal = () => {
        setIncomeModalVisible(true);
    };

    const hideIncomeModal = () => {
        setIncomeModalVisible(false);
    }

    const showAllModal = () => {
        setAllModalVisible(true);
    };

    const hideAllModal = () => {
        setAllModalVisible(false);
    };

    return (
        <>
            {loading ? (
                <View style={styles.loaderContainer} >
                    <Loader size="large" />
                </View>
            ) : (
                <View style={styles.container}>
                    <View style={styles.totalBalanceTile}>
                        <DropDownPicker
                            placeholder='Select User'
                            style={styles.roleDropDown}
                            dropDownContainerStyle={styles.roleDropDown1}
                            open={open}
                            value={value}
                            items={users}
                            setOpen={(val) => {
                                setOpen(val)
                            }
                            }
                            setValue={setValue}
                            setItems={users}
                        />
                        <Text style={styles.totalBalanceAmount}>{'₹ ' + totalBalance}</Text>
                    </View>
                    <View style={[styles.dateRow, { marginTop: 60 }]}>
                        <Text style={styles.text}>Start Date:</Text>
                        <TouchableOpacity onPress={showStartDatePicker}>
                            <View style={styles.view}>
                                <FontAwesome5
                                    name="calendar"
                                    size={24}
                                    color={Colors.primary}
                                />
                                <Text style={styles.dateText}> {startDate.toDateString()}</Text>
                                <FontAwesome5
                                    name="caret-down"
                                    size={24}
                                    color={Colors.primary}
                                />
                                <Popup visible={isStartDatePickerVisible} onClose={() => setStartDatePickerVisibility(false)}>
                                    <DateTimePicker
                                        mode="single"
                                        date={startDate || new Date()}
                                        maxDate={today}
                                        onChange={(param) => {
                                            handleStartDateConfirm(new Date(param.date));
                                            setStartDatePickerVisibility(false)
                                        }}
                                        headerButtonStyle={{ color: 'green' }}
                                    />
                                </Popup>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.dateRow}>
                        <Text style={styles.text}>End Date:</Text>
                        <TouchableOpacity onPress={showEndDatePicker}>
                            <View style={styles.view}>
                                <FontAwesome5
                                    name="calendar"
                                    size={24}
                                    color={Colors.primary}
                                />
                                <Text style={styles.dateText}> {endDate.toDateString()}</Text>
                                <FontAwesome5
                                    name="caret-down"
                                    size={24}
                                    color={Colors.primary}
                                />
                                <Popup visible={isEndDatePickerVisible} onClose={() => setEndDatePickerVisibility(false)}>
                                    <DateTimePicker
                                        mode="single"
                                        date={endDate || new Date()}
                                        maxDate={today}
                                        onChange={(param) => {
                                            handleEndDateConfirm(new Date(param.date));
                                            setEndDatePickerVisibility(false)
                                        }}
                                        headerButtonStyle={{ color: 'green' }}
                                    />
                                </Popup>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showAllModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>All Transaction</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showEmpModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>Employees</Text>
                            </TouchableOpacity>
                            <Text style={[styles.cell, styles.red]}>{empAmount}</Text>
                            <Text style={styles.cell}>-</Text>
                        </View>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showSuppModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>Suppliers</Text>
                            </TouchableOpacity>
                            <Text style={[styles.cell, styles.red]}>{suppAmount}</Text>
                            <Text style={styles.cell}>-</Text>
                        </View>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showClientModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>Clients</Text>
                            </TouchableOpacity>
                            <Text style={styles.cell}>-</Text>
                            <Text style={[styles.cell, styles.green]}>{clientAmount}</Text>
                        </View>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showAccountModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>Accounts</Text>
                            </TouchableOpacity>
                            <Text style={[styles.cell, styles.red]}>{accountAmount}</Text>
                            <Text style={[styles.cell]}>-</Text>
                        </View>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showThekedarModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>Thekedars</Text>
                            </TouchableOpacity>
                            <Text style={[styles.cell, styles.red]}>{thekedarAmount}</Text>
                            <Text style={[styles.cell]}>-</Text>
                        </View>
                        <View style={styles.row}>
                            <TouchableOpacity style={styles.cell} onPress={showIncomeModal}>
                                <Text style={[styles.header, { color: Colors.blue }]}>Other Income</Text>
                            </TouchableOpacity>
                            <Text style={[styles.cell]}>-</Text>
                            <Text style={[styles.cell, styles.green]}>{incomeAmount}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell, styles.header]}>Total</Text>
                            <Text style={[styles.cell, styles.red]}>{empAmount + suppAmount + accountAmount + thekedarAmount}</Text>
                            <Text style={[styles.cell, styles.green]}>{clientAmount + incomeAmount}</Text>
                        </View>
                    </View>
                    <TransactionModal
                        data={alltransaction}
                        visible={isAllModalVisible}
                        closeModal={hideAllModal}
                        title="All Transactions"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalance}
                        closingBalance={closingBalance}
                    />
                    <TransactionModal
                        data={clientList}
                        visible={isClientModalVisible}
                        closeModal={hideClientModal}
                        title="Clients Transactions"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalanceClient}
                        closingBalance={closingBalanceClient}
                    />
                    <TransactionModal
                        data={empList}
                        visible={isEmpModalVisible}
                        closeModal={hideEmpModal}
                        title="Employees Transactions"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalanceEmployee}
                        closingBalance={closingBalanceEmployee}
                    />
                    <TransactionModal
                        data={suppList}
                        visible={isSuppModalVisible}
                        closeModal={hideSuppModal}
                        title="Suppliers Transactions"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalanceSupplier}
                        closingBalance={closingBalanceSupplier}
                    />
                    <TransactionModal
                        data={accountList}
                        visible={isAccountModalVisible}
                        closeModal={hideAccountModal}
                        title="Accounts Transactions"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalanceAccounts}
                        closingBalance={closingBalanceAccounts}
                    />
                    <TransactionModal
                        data={thekedarList}
                        visible={isThekedarModalVisible}
                        closeModal={hideThekedarModal}
                        title="Thekedars Transactions"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalanceThekedar}
                        closingBalance={closingBalanceThekedar}
                    />
                    <TransactionModal
                        data={otherIncomeList}
                        visible={isIncomeModalVisible}
                        closeModal={hideIncomeModal}
                        title="Other Income"
                        startDate={startDate}
                        endDate={endDate}
                        balance={totalBalance}
                        openingBalance={openingBalanceOther}
                        closingBalance={closingBalanceOther}
                    />
                </View>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        marginLeft: 10,
        marginRight: 10,
        fontWeight: 'bold',
    },
    dateRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    view: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 45,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 6,
        width: '100%',
        backgroundColor: "white"
    },
    dateText: {
        marginLeft: 10,
        marginRight: 10,
    },
    totalBalanceTile: {
        position: 'absolute',
        top: '2%',
        width: '80%',
        height: '16%',
        backgroundColor: 'lightblue',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 4, // Shadow elevation for Android
        shadowColor: '#000', // Shadow properties for iOS
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        zIndex: 1000,
        padding: 20
    },
    totalBalanceText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    totalBalanceAmount: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10
    },
    table: {
        borderWidth: 1,
        borderColor: '#000',
        top: '3%',
        borderRadius: 5,
        overflow: 'hidden',
        width: '80%', // Adjust the width as per your requirement
    },
    row: {
        flexDirection: 'row',
    },
    cell: {
        flex: 1,
        padding: 10,
        textAlign: 'center',
        borderWidth: 1,
        borderColor: '#000',
        fontWeight: 'bold',
        verticalAlign: 'middle'
    },
    header: {
        fontWeight: 'bold',
        backgroundColor: '#f0f0f0',
        textAlign: 'center'
    },
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
    roleDropDown: {
        backgroundColor: 'transparent',
    },
    roleDropDown1: {
        backgroundColor: 'lightblue',
    }
});

export default DayTransactionScreen;
